import Text from "../../Text";
import Container from "@/Components/Backend/Grid/Container";
import Row from "@/Components/Backend/Grid/Row";
import Column from "@/Components/Backend/Grid/Column";
import { LocationMapProps } from "@/types/components/front/blocks/locationMap";

import "./style.scss";
import IconHeadingSection from "../IconHeadingSection/IconHeadingSection";

const LocationMap = ({
    sectionHeading,
    iframe,
    layout = "default",
}: LocationMapProps) => {
    return (
        <div className={`${layout === "default" && "md:pt-[52px] pt-7"}`}>
            {layout === "default" ? (
                <></>
            ) : (
                <></>
            )}
                <IconHeadingSection className="pb-2 pt-12">
                    {sectionHeading}
                </IconHeadingSection>
            <div
                className={`w-full h-full iframe-holder ${
                    layout === "default" ? "pt-6" : "pt-12"
                }`}
                onScroll={(e) => {e.stopPropagation()}}
                onWheel={(e) => {e.stopPropagation()}}
            >
                {iframe}
            </div>
        </div>
    );
};

export default LocationMap;
